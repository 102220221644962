@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  color: #fff;
  background-image: linear-gradient(
    123deg,
    #008cff 0%,
    #0468da 20%,
    #57ffeb 80%
  );
}

.button--black {
  background: linear-gradient(to right, #6d6d6d, #333333);
  background-color: #529ffa;
  color: #fff;
  width: 10rem;
  height: 3.5rem;
  font-family: Poppins;
  font-size: 1.3rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 0 0.5rem 0 0;
  border: 0px solid #000;
  border-radius: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button--black span {
  margin-right: 9px;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  background-image: url(/public/images/main/icons/icons8-github.png);
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg);
}
.button--black:hover {
  background: linear-gradient(to right, #6d6d6d, #535353);
  transform: scale(1.05);
}
.button--black:active {
  transform: scale(0.95);
}

.button--blue {
  background: linear-gradient(to right, #529ffa, #005bd2);
  background-color: #529ffa;
  color: #fff;
  width: 10rem;
  height: 3.5rem;
  font-family: Poppins;
  font-size: 1.3rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 0 0.5rem 0 0;
  border: 0px solid #000;
  border-radius: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button--blue span {
  margin-right: 9px;
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(/public/images/main/icons/icons8-play-64.png);
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg);
}
.button--blue:hover {
  background: linear-gradient(to right, #70abfb, #4776ec);
  transform: scale(1.05);
}
.button--blue:active {
  transform: scale(0.95);
}

.button--download {
  background: linear-gradient(to right, #529ffa, #005bd2);
  background-color: #529ffa;
  color: #fff;
  width: 13rem;
  height: 3.5rem;
  font-family: Poppins;
  font-size: 1.3rem;
  font-weight: 600;
  font-style: normal;
  text-decoration: none;
  padding: 0 0.5rem 0 0;
  border: 0px solid #000;
  border-radius: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button--download span {
  margin-right: 9px;
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(/public/images/main/icons/icons8-download-64.png);
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(0deg);
}
.button--download:hover {
  background: linear-gradient(to right, #70abfb, #4776ec);
  transform: scale(1.05);
}
.button--download:active {
  transform: scale(0.95);
}

@media (max-width: 640px) {
}

@media (max-width: 768px) {
}

@media (max-width: 1024px) {
}

@media (max-width: 1280px) {
  .button--black {
    width: 8rem;
    height: 3rem;
    font-size: 1.1rem;
  }
  .button--black span {
    margin-right: 9px;
    width: 35px;
    height: 35px;
  }
  .button--blue {
    width: 8rem;
    height: 3rem;
    font-size: 1.1rem;
  }
  .button--blue span {
    margin-right: 9px;
    width: 35px;
    height: 35px;
  }
  .button--download {
    width: 11rem;
    height: 3rem;
    font-size: 1.1rem;
  }
  .button--download span {
    margin-right: 9px;
    width: 35px;
    height: 35px;
  }
}
